import { ApiUrl } from "./Shared";
import axiosObject from "../utils/InterceptorService";

export const addLead = async (leadRequestModel)=>{
   let res = await axiosObject.post(`${ApiUrl}Leads/register`,leadRequestModel).then(res=>res.data);
   return res;
}

export const getAllLeads =async()=>{
    let res = await axiosObject.get(`${ApiUrl}Leads/GetAllLeads-ByCompany`).then(res=>res.data)
    return res
}
export const deleteLeadById=async (leadId)=>{
    let res = await axiosObject.delete(`${ApiUrl}Leads/deleteLeadById/${leadId}`).then(res=>res.data)
    return res;
}
export const updateLead=async (lead)=>{
    const res = await axiosObject.post(`${ApiUrl}Leads/Update-Lead`,lead).then(res=>res.data)
    return res;
}
export const addLeadProcessStep=async (leadProcessStep)=>{
    const res = await axiosObject.post(`${ApiUrl}Leads/addLeadProcessStep`,leadProcessStep).then(res=>res.data)
    return res;
}
export const addFollowUpdate=async (followUpdate)=>{
     const res = await axiosObject.post(`${ApiUrl}Leads/addLeadFollowUpdate`,followUpdate).then(res=>res.data)
        return res;
}
export const addLeadComment=async(commentModel)=>{
    let res = axiosObject.post(`${ApiUrl}Leads/addLeadComment`,commentModel).then(res=>res.data)
    return res;
}
export const leadFollowUpHistory =async (leadId)=>{
        let res = await axiosObject.get(`${ApiUrl}Leads/showLeadFollowUpHistory/${leadId}`).then(res=>res.data)
        return res;
}
export const addManageLead =async(manageLead) =>{
    let res = await axiosObject.post(`${ApiUrl}Leads/AddManageLead`,manageLead).then(res =>res.data)
    return res;
}
export const todaysFollowUp=async (leadTodaysFollowUp)=>{
    let res=await axiosObject.post(`${ApiUrl}Leads/TodaysFollowUpDate`,leadTodaysFollowUp).then(res=> res.data) 
    return res;
}
export const addTimeSheet =async(timeSheetModel)=>{
    let res=await axiosObject.post(`${ApiUrl}Leads/addTimeSheet`,timeSheetModel).then(res=>res.data)
    return res;
}
export const timeSheetList=async()=>{
    let res=await axiosObject.get(`${ApiUrl}Leads/getTimeSheetList`).then(res=>res.data)
    return res;
}
export const updateTimeSheet =async(updateTimeSheet)=>{
    let res= await axiosObject.post(`${ApiUrl}Leads/updateTimeSheet`,updateTimeSheet).then(res =>res.data)
    return res;
}
export const deleteTimeSheetById =async (id)=>{
    let res =await axiosObject.delete(`${ApiUrl}Leads/deleteTimeSheetById/${id}`).then(res=>res.data);
    return res;
}
export const addLeadCompanyName=async(model)=>{
    let res= await axiosObject.post(`${ApiUrl}Leads/registerLeadCompany`,model).then(res=>res.data)
    return res;
}
export const getAllLeadCompanies=async()=>{
    let res=await axiosObject.get(`${ApiUrl}Leads/getAllLeadCompanyNames`).then(res=>res.data);
    return res;
}
export const deletLeadCompanyById=(id)=>{
    let res=axiosObject.delete(`${ApiUrl}Leads/deleteLeadCompanyById/${id}`).then(res=>res.data);
    return res;
} 
export const updateLeadCompany= (model)=>{
    let res=axiosObject.post(`${ApiUrl}Leads/updateLeadCompany`,model).then(res=>res.data)
    return res;
}
export const getLeadCategoriesByCompany =()=>{
    let res =axiosObject.get(`${ApiUrl}Leads/getLeadCategoriesByCompany`).then(res=>res.data);
    return res;
}
export const listOfTimeSheetStepsByCompany =async()=>{
    let res =await axiosObject.get(`${ApiUrl}Leads/GetAllTimeSheetStepsByCompany`).then(res=>res.data);
    return res;
}
export const addBulkLeads=async (model)=>{
    let res=await axiosObject.post(`${ApiUrl}Leads/addMultipleLeads`,model).then(res=>res.data);
    return res;
}
export const getAllProjectsByUserLead =async ()=>{
    let res=await axiosObject.get(`${ApiUrl}Leads/getProjectsByUser`).then(res=>res.data);
    return res;
}
